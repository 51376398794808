import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { mapGetters } from 'vuex';
import { quillEditor } from 'vue-quill-editor';
import axios from 'axios';
import Head from '@/components/head/index2';
import Heads from '@/components/head/index';
import { agentFlowerNameList } from '@/api/ceshi_xiugai/whole';
import { SystemManualInfo, SystemManualEdit, OperatorDataIndex, CallTianRun, CallIncoming } from '@/api/article';
import { getlower_structure_list } from '@/api/updateUserInfo/structure';
import { getStructureListArr } from '@/api/PublicAPI';
import { homelookcar, Studentleve_list, circulation_list, executive_car, executive_Studentleve, executive_circulation, CallStatistics, Callperson_list, Personshare_list, personsource_list } from '@/api/homelook';
export default {
  name: 'Dashboard',
  components: {
    Head: Head,
    quillEditor: quillEditor,
    Heads: Heads
  },
  data: function data() {
    var _ref;
    return _ref = {
      loading: true,
      title: '看板',
      prohibit: false,
      is_director: this.$store.state.user.is_director,
      tabPaneArr: [],
      shuId: '',
      display: false,
      //显示主管还是人员
      //珠宝看板
      titled1: '今日学员级别详情',
      titled2: '今日流转数据详情',
      titled3: '昨日通话数据监控详情',
      activeName: 'first',
      //选项卡
      // 主管
      studentJudge: false,
      theJudge: false,
      conversationJudge: false,
      // 用户
      studentJudge1: false,
      theJudge1: false,
      conversationJudge1: false,
      conversationJudge2: false,
      flowJudge1: false,
      channelJudge1: false,
      //主管卡片
      executive_carData: [{
        title: '今日新增客户',
        count: 0,
        color: 'color:#F1740E;',
        icon: 'icon-kehu1  iconfont',
        name: 'new_num'
      }, {
        title: '今日跟进客户',
        count: 0,
        color: 'color:#2E71FC;',
        icon: 'icon-genjin1 iconfont',
        name: 'followed_count'
      }, {
        title: '昨日通话次数 ',
        count: 0,
        color: 'color:#009EFF;',
        icon: 'icon-tonghua iconfont',
        name: 'call_num'
      }, {
        title: '昨日有效通话次数',
        count: 0,
        color: 'color:#F87272;',
        icon: 'icon-dianhua iconfont',
        name: 'valid_call_num'
      }, {
        title: '昨日通话时长',
        count: 0,
        color: 'color:#59C573 ;',
        icon: 'icon-pingjuntonghuashichang iconfont',
        name: 'call_time_count'
      }, {
        title: '今日缴费客户',
        count: 0,
        color: 'color:#FBB74A;',
        icon: 'icon-jiaofei2 iconfont',
        name: 'jifei_person_count'
      }, {
        title: '今日收资料客户',
        count: 0,
        color: 'color:#66CCBB;',
        icon: 'icon-ziliao1 iconfont',
        name: 'ziliao_person_count'
      }, {
        title: '今日转出客户',
        count: 0,
        color: 'color:#FA5A5A;',
        icon: 'icon-kehu2 iconfont',
        name: 'zhuan_person_count'
      }],
      bumenoptions: [],
      //部门数组
      Studentleve_eid: '',
      //今日学员级别详情id
      Studentleve_elist: [],
      //今日学员级别详情
      Studentleve_json: '',
      //今日学员级别详情
      Studentleve_elistTo: [],
      //今日学员级别详情
      circulation_eid: '',
      //今日流转数据详id
      circulation_elist: [],
      //今日流转数据详
      conversation_eid: '',
      //今日通话数据监控详情id
      conversation_elist: [],
      //今日通话数据监控详情
      // 用户看板
      user_titled1: '学员级别详情',
      user_titled2: '流转数据详情',
      user_titled3: '昨日通话数据监控详情',
      user_titled6: '通话数据统计',
      user_titled7: '呼入通话统计',
      user_titled4: '流量共享统计',
      user_titled5: '渠道来源统计',
      //卡片颜色
      colorData: ['linear-gradient(180deg, #9BD0FF 0%, #62A4FD 100%)', 'linear-gradient(180deg, #FB8F8C 0%, #FB8F8C 100%)', 'linear-gradient(180deg, #F6C245 0%, #F4B646 100%)', 'linear-gradient(180deg, #00E099 0%, #2AC19B 100%)'],
      //卡片
      user_carData: [],
      StudentleveData: [],
      //学员级别详情list
      StudentleveTos: [],
      //学员级别详情list
      Studentleve_time: '',
      //学员级别详情时间筛选
      circulation_time: '',
      //流转数据详情筛选
      circulationData: [],
      //流转数据详情
      conversation_time: '',
      //通话数据监控详情时间筛选
      conversation_tian: '',
      //通话数据监控详情时间筛选
      conversation_tian1: '',
      //呼入通话监控详情时间筛选
      conversationdata: [],
      //通话数据监控详情
      conversatiandata: [],
      //天润通话数据监控详情
      conversatiandata1: [],
      //呼入通话监控详情
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now(); //禁止选择以后的时间
        }
      },
      //流量共享统计时间筛选
      sharing_time: '',
      create_stime: '',
      create_etime: '',
      structure_ids: '',
      sharingData: []
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "bumenoptions", []), "sharing_Tos", []), "Studentleve_id", ''), "channel_time", ''), "channel_stime", ''), "channel_etime", ''), "structure_ids", ''), "channelData", []), "channeltions", []), "channel_Tos", []), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "channelleve_id", ''), "source_client_id", []), "flower_Array", []), "courseName", 'introduction'), "coursekanban", 'director'), "tableData1", []), "menuId", ''), "menuIds", ''), "course", this.$route.query.course), "prohibit", false), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "tutorialEs", false), "total", 0), "page", 1), "pagesize", 15), "centerHtml", ''), "dialogFormVisible", false), "editorOption", {
      placeholder: 'Hello World'
    }), "editForm", {
      content: ''
    }), "pageOperations", false), "IDesv", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "IDesarr", []), "operatorShow", false), "userNameshow", false), "typeShow", false), "operationDataShow", false), "BelongingShow", false), "herhnm", []), "kshasa", []), "finalFollow", ''), "start_follow_time", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "end_follow_time", ''), "structure_id", ''), "bumenoptions", []), "Buried", {
      search_username: '',
      search_realname: '',
      auth_rule_type: '1',
      search_create_time: '',
      search_structure_ids: '',
      search_auth_rule_title: ''
    }), "BuriedData1", []), "executiveShow", false), "studentLevelId", 0), "flowDataId", 0), "callDataId", 0), "userShow", false), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "studentLevelId2", 0), "flowDataId2", 0), "callDataId2", 0), "trafficSharingId", 0), "channelSource", 0);
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  mounted: function mounted() {
    var _this = this;
    localStorage.setItem('menuId', '339');
    this.menuId = localStorage.getItem('menuId');
    this.obtainId();
    if (this.course != 1) {
      this.showHiddin();
      this.is_director = this.$store.state.user.is_director;
      if (this.is_director == 0) {
        if (this.activeName == 'first') {
          this.display = false;
          this.getStructure();
          this.user_Summary(); //用户看板数据
        }
      }
      if (this.is_director == 1) {
        if (this.activeName == 'first') {
          this.display = true;
          // this.user_Summary(); //用户看板数据
          this.getStructure();
          this.executive_Summary(); //主管数据
        }
      }
    } else {
      SystemManualInfo({
        role_id: this.menuId
      }).then(function (res) {
        _this.centerHtml = res.data.contents;
      });
    }
  },
  methods: {
    detailsClick: function detailsClick() {
      var _this2 = this;
      //系统教程
      SystemManualInfo({
        role_id: this.menuId
      }).then(function (res) {
        _this2.editForm.content = res.data.contents;
        _this2.dialogFormVisible = true;
      });
    },
    showHiddin: function showHiddin() {
      var _this3 = this;
      if (this.activeName == 'second') {
        var JsonArr1 = this.$store.getters.JsonArr1[0].children;
        var JsonArr2 = this.$store.getters.JsonArr1[1].children;
        if (JsonArr1 != undefined) {
          JsonArr1.forEach(function (item) {
            if (item.title == '今日学员级别详情') {
              _this3.studentJudge = true;
            } else if (item.title == '今日流转数据详情') {
              _this3.theJudge = true;
            } else if (item.title == '通话数据监控详情') {
              _this3.conversationJudge = true;
            }
          });
        }
        if (JsonArr2 != undefined) {
          JsonArr2.forEach(function (item) {
            if (item.title == '学员级别详情') {
              _this3.studentJudge1 = true;
            } else if (item.title == '流转数据详情') {
              _this3.theJudge1 = true;
            } else if (item.title == '昨日通话数据监控详情') {
              _this3.conversationJudge1 = true;
            } else if (item.title == '呼入通话监控详情') {
              _this3.conversationJudge2 = true;
            } else if (item.title == '流量共享统计') {
              _this3.flowJudge1 = true;
            } else if (item.title == '渠道来源统计') {
              _this3.channelJudge1 = true;
            }
          });
        }
      } else {
        var _JsonArr = this.$store.getters.JsonArr1[0].children;
        var _JsonArr2 = this.$store.getters.JsonArr1[1].children;
        if (_JsonArr != undefined) {
          _JsonArr.forEach(function (item) {
            if (item.title == '今日学员级别详情') {
              _this3.studentJudge = true;
            } else if (item.title == '今日流转数据详情') {
              _this3.theJudge = true;
            } else if (item.title == '通话数据监控详情') {
              _this3.conversationJudge = true;
            }
          });
        }
        if (_JsonArr2 != undefined) {
          _JsonArr2.forEach(function (item) {
            if (item.title == '学员级别详情') {
              _this3.studentJudge1 = true;
            } else if (item.title == '流转数据详情') {
              _this3.theJudge1 = true;
            } else if (item.title == '昨日通话数据监控详情') {
              _this3.conversationJudge1 = true;
            } else if (item.title == '呼入通话监控详情') {
              _this3.conversationJudge2 = true;
            } else if (item.title == '流量共享统计') {
              _this3.flowJudge1 = true;
            } else if (item.title == '渠道来源统计') {
              _this3.channelJudge1 = true;
            }
          });
        }
      }
    },
    obtainId: function obtainId() {
      var _this4 = this;
      var jurisdiction = [];
      this.IDesarr = [];
      this.kshasa = [];
      var tion = this.$store.getters.StatisTics;
      var diction = this.$store.getters.JsonArr1;
      diction.forEach(function (item) {
        if (item.children != undefined) {
          item.children.forEach(function (event) {
            _this4.IDesarr.push(event);
            _this4.kshasa.push(event.title);
          });
        }
      });
      if (tion[0] != undefined) {
        tion.forEach(function (item) {
          jurisdiction.push(item.title);
        });
        if (jurisdiction.indexOf('页面操作统计') <= -1) {
          this.$nextTick(function () {
            _this4.$refs.tabs.$children[0].$refs.tabs[0].style.display = 'none';
            _this4.$refs.tabs.$children[0].$refs.tabs[1].style.display = 'none';
          });
        }
        if (jurisdiction.indexOf('编辑教程') > -1) {
          this.tutorialEs = true;
        } else {
          this.tutorialEs = false;
        }
      } else {
        this.$nextTick(function () {
          if (_this4.$refs.tabs != undefined) {
            _this4.$refs.tabs.$children[0].$refs.tabs[0].style.display = 'none';
            _this4.$refs.tabs.$children[0].$refs.tabs[1].style.display = 'none';
          }
        });
        this.tutorialEs = false;
      }
    },
    statisticalTable: function statisticalTable(page, pagesize) {
      var _this5 = this;
      this.Buried.page = page;
      this.Buried.pagesize = pagesize;
      this.Buried.auth_rule_pid = this.menuId;
      OperatorDataIndex(this.Buried).then(function (res) {
        _this5.BuriedData1 = res.data.data;
        _this5.total = res.data.total;
      });
    },
    handleClick: function handleClick() {
      if (this.is_director == 0) {
        return;
      } else {
        if (this.activeName == 'second') {
          this.user_Summary(); //用户看板数据
        } else {
          this.executive_Summary(); //主管数据
        }
      }
      this.showHiddin();
    },
    courseClick: function courseClick() {
      var _this6 = this;
      if (this.courseName = 'statistics') {
        getStructureListArr().then(function (respomse) {
          _this6.bumenoptions = respomse.data;
        });
        this.statisticalTable(this.page, this.pagesize);
      }
    },
    StatisticsClick: function StatisticsClick() {
      this.operatorShow = false;
      this.userNameshow = false;
      this.typeShow = false;
      this.operationDataShow = false;
      this.BelongingShow = false;
      this.Buried = {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      };
      if (this.coursekanban == 'director') {
        this.Buried.auth_rule_type = '1';
      } else {
        this.Buried.auth_rule_type = '2';
      }
      this.statisticalTable(this.page, this.pagesize);
    },
    operatorClick: function operatorClick() {
      //操作人
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    userNamesClick: function userNamesClick() {
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    noteAppendedClick: function noteAppendedClick() {
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      this.page = 1;
      if (this.finalFollow == null) {
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.page, this.pagesize);
      } else {
        this.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
        this.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.page, this.pagesize);
      }
    },
    structureclick: function structureclick(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.page = 1;
      this.Buried.search_structure_ids = adad;
      this.statisticalTable(this.page, this.pagesize);
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.pagesize = val;
      this.statisticalTable(this.page, val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.page = val;
      this.statisticalTable(val, this.pagesize);
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '操作人') {
        this.operatorShow = true;
      } else if (item === '用户名') {
        this.userNameshow = true;
      } else if (item === '操作日期') {
        this.operationDataShow = true;
      } else if (item === '操作人所属部门') {
        this.BelongingShow = true;
      } else if (item === '操作类型') {
        this.typeShow = true;
      }
    },
    Deletesshow: function Deletesshow(item) {
      this.page = 1;
      if (item === '操作人') {
        this.operatorShow = false;
        this.Buried.search_realname = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '用户名') {
        this.userNameshow = false;
        this.Buried.search_username = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作日期') {
        this.operationDataShow = false;
        this.Buried.search_create_time = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作人所属部门') {
        this.BelongingShow = false;
        this.structure_id = '';
        this.Buried.search_structure_ids = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作类型') {
        this.typeShow = false;
        this.Buried.search_auth_rule_title = '';
        this.statisticalTable(this.page, this.pagesize);
      }
    },
    //数据统计范围树状图
    getStructure: function getStructure() {
      var _this7 = this;
      getStructureListArr({
        type: '1',
        get_type: '0'
      }).then(function (res) {
        _this7.bumenoptions = res.data;
      });
    },
    //主管数据
    executive_Summary: function executive_Summary() {
      var _this8 = this;
      //主管看板卡片
      executive_car().then(function (e_car) {
        _this8.executive_carData.forEach(function (item, index) {
          for (var val in e_car.data) {
            if (val == item.name) {
              item.count = e_car.data[val];
            }
          }
        });
        _this8.loading = false;
      });
      //主管今日学员级别详情
      executive_Studentleve({
        structure_ids: ''
      }).then(function (e_list) {
        _this8.Studentleve_elist = e_list.data.data;
        var name = {
          prop: 'name',
          label: '部门',
          width: '130'
        };
        e_list.data.tabletitle.unshift(name);
        _this8.Studentleve_elistTo = e_list.data.tabletitle;
      });
      //主管今日流转数据详情
      executive_circulation().then(function (resc) {
        _this8.circulation_elist = resc.data;
      });
      //通话数据监控详情
      // CallStatistics().then((callres) => {
      //     this.conversation_elist = callres.data;
      // });
    },
    //用户看板数据
    user_Summary: function user_Summary() {
      var _this9 = this;
      //用户看板 卡片
      homelookcar().then(function (res) {
        _this9.user_carData = [];
        res.data.forEach(function (item, index) {
          _this9.user_carData.push({
            id: index,
            list: item
          });
        });
        _this9.loading = false;
      });
      //学员级别详情初始时间
      this.Studentleve_time = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];
      //流转数据详情初始时间
      this.circulation_time = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];
      //通话数据监控详情初始时间
      this.conversation_time = [new Date().setHours(-23, 0, 0, 0), new Date().setHours(-23, 59, 59, 999)];
      this.conversation_tian = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];
      //呼入通话监控详情初始时间
      // this.conversation_time = [new Date().setHours(-23, 0, 0, 0), new Date().setHours(-23, 59, 59, 999)];
      this.conversation_tian1 = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];
      //流量共享统计初始时间
      this.sharing_time = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];
      this.create_stime = String(this.sharing_time[0]).substr(0, 10);
      this.create_etime = String(this.sharing_time[1]).substr(0, 10);
      this.sharing(this.create_stime, this.create_etime);
      //渠道来源统计初始时间
      this.channel_time = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];
      this.channel_stime = String(this.channel_time[0]).substr(0, 10);
      this.channel_etime = String(this.channel_time[1]).substr(0, 10);
      this.channel(this.channel_stime, this.channel_etime);
      this.flowerArray();
      //学员级别详情list
      Studentleve_list({
        seas_time: String(this.Studentleve_time[0]).substr(0, 10),
        seae_time: String(this.Studentleve_time[1]).substr(0, 10)
      }).then(function (res_xue) {
        // this.StudentleveData = [];
        _this9.StudentleveData = res_xue.data.data;
        _this9.StudentleveTos = res_xue.data.tabletitle;
      });
      //个人流转数据详情
      circulation_list({
        start_time: String(this.circulation_time[0]).substr(0, 10),
        send_time: String(this.circulation_time[1]).substr(0, 10)
      }).then(function (res_liu) {
        _this9.circulationData = [];
        _this9.circulationData.push(res_liu.data);
      });
      //通话数据监控详情
      // Callperson_list().then((callres) => {
      //     this.conversationdata = [];
      //     this.conversationdata.push(callres.data);
      // });
      CallTianRun({
        search_date_start: String(this.conversation_tian[0]).substr(0, 10),
        search_date_end: String(this.conversation_tian[1]).substr(0, 10)
      }).then(function (res) {
        _this9.conversatiandata = [];
        _this9.conversatiandata.push(res.data);
      });
      CallIncoming({
        search_date_start: String(this.conversation_tian1[0]).substr(0, 10),
        search_date_end: String(this.conversation_tian1[1]).substr(0, 10)
      }).then(function (res) {
        _this9.conversatiandata1 = [];
        _this9.conversatiandata1.push(res.data);
      });
    },
    addDateCall: function addDateCall() {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      };
      var newmonth = date.month >= 10 ? date.month : '0' + date.month;
      var day = date.date >= 10 ? date.date : '0' + date.date;
      var time = date.year + newmonth + day;
      return time;
    },
    //主管学员级别详情查询
    Studentleve_eChange: function Studentleve_eChange(name) {
      var _this10 = this;
      if (this.Studentleve_eid !== '') {
        var share = this.Studentleve_eid.map(function (e) {
          return e[e.length - 1];
        }).toString(); // 部门的最后一位ID
        var adad = share == ',' ? '' : share;
        if (this.kshasa.indexOf(name) != -1) {
          this.IDesarr.forEach(function (item) {
            if (item.title == name) {
              executive_Studentleve({
                auth_rule_id: item.id,
                structure_ids: adad
              }).then(function (e_list) {
                _this10.Studentleve_elist = e_list.data.data;
                var name = {
                  prop: 'name',
                  label: '部门',
                  width: '130'
                };
                e_list.data.tabletitle.unshift(name);
                _this10.Studentleve_elistTo = e_list.data.tabletitle;
              });
            }
          });
        } else {
          executive_Studentleve({
            structure_ids: adad
          }).then(function (e_list) {
            _this10.Studentleve_elist = e_list.data.data;
            var name = {
              prop: 'name',
              label: '部门',
              width: '130'
            };
            e_list.data.tabletitle.unshift(name);
            _this10.Studentleve_elistTo = e_list.data.tabletitle;
          });
        }
      }
    },
    //主管流转数据详情
    circulation_eChange: function circulation_eChange(name) {
      var _this11 = this;
      if (this.circulation_eid !== '') {
        var share = this.circulation_eid.map(function (e) {
          return e[e.length - 1];
        }).toString(); // 部门的最后一位ID
        var adad = share == ',' ? '' : share;
        if (this.kshasa.indexOf(name) != -1) {
          this.IDesarr.forEach(function (item) {
            if (item.title == name) {
              executive_circulation({
                auth_rule_id: item.id,
                structure_ids: adad
              }).then(function (resc) {
                _this11.$nextTick(function () {
                  _this11.circulation_elist = resc.data;
                });
              });
            }
          });
        } else {
          executive_circulation({
            structure_ids: adad
          }).then(function (resc) {
            _this11.$nextTick(function () {
              _this11.circulation_elist = resc.data;
            });
          });
        }
      }
    },
    //主管通话数据监控详情
    // conversation_eChang(name) {
    //     if (this.conversation_eid !== '') {
    //         const share = this.conversation_eid.map((e) => e[e.length - 1]).toString(); // 部门的最后一位ID
    //         let adad = share == ',' ? '' : share;
    //         if (this.kshasa.indexOf(name) != -1) {
    //             this.IDesarr.forEach((item) => {
    //                 if (item.title == name) {
    //                     CallStatistics({ auth_rule_id: item.id, structure_id: adad.toString() }).then((res_liu) => {
    //                         this.$nextTick(() => {
    //                             this.conversation_elist = res_liu.data;
    //                         });
    //                     });
    //                 }
    //             });
    //         } else {
    //             CallStatistics({ structure_id: adad.toString() }).then((res_liu) => {
    //                 this.$nextTick(() => {
    //                     this.conversation_elist = res_liu.data;
    //                 });
    //             });
    //         }
    //     }
    // },
    //用户看板
    // 用户学员级别详情筛选
    Studentleve_event: function Studentleve_event(name) {
      var _this12 = this;
      this.StudentleveData = [];
      if (this.Studentleve_time == null || this.Studentleve_time == '') {
        if (this.kshasa.indexOf(name) != -1) {
          this.IDesarr.forEach(function (item) {
            if (item.title == name) {
              Studentleve_list({
                auth_rule_id: item.id
              }).then(function (reslist) {
                _this12.StudentleveData = reslist.data.data;
                _this12.StudentleveTos = reslist.data.tabletitle;
              });
            }
          });
        } else {
          Studentleve_list().then(function (reslist) {
            _this12.StudentleveData = reslist.data.data;
            _this12.StudentleveTos = reslist.data.tabletitle;
          });
        }
      } else {
        var seas_time = this.Studentleve_time[0].toString().substring(0, 10);
        var seae_time = this.Studentleve_time[1].toString().substring(0, 10);
        if (this.kshasa.indexOf(name) != -1) {
          this.IDesarr.forEach(function (item) {
            if (item.title == name) {
              Studentleve_list({
                auth_rule_id: item.id,
                seas_time: seas_time,
                seae_time: seae_time
              }).then(function (reslist) {
                _this12.StudentleveData = reslist.data.data;
                _this12.StudentleveTos = reslist.data.tabletitle;
              });
            }
          });
        } else {
          Studentleve_list({
            seas_time: seas_time,
            seae_time: seae_time
          }).then(function (reslist) {
            _this12.StudentleveData = reslist.data.data;
            _this12.StudentleveTos = reslist.data.tabletitle;
          });
        }
      }
    },
    // 用户流量共享统计时间筛选
    sharing_event: function sharing_event(name) {
      var _this13 = this;
      if (this.kshasa.indexOf(name) != -1) {
        this.IDesarr.forEach(function (item) {
          if (item.title == name) {
            _this13.create_stime = _this13.sharing_time[0].toString().substring(0, 10);
            _this13.create_etime = _this13.sharing_time[1].toString().substring(0, 10);
            _this13.sharing(_this13.create_stime, _this13.create_etime, item.id);
          }
        });
      } else {
        this.create_stime = this.sharing_time[0].toString().substring(0, 10);
        this.create_etime = this.sharing_time[1].toString().substring(0, 10);
        this.sharing(this.create_stime, this.create_etime);
      }
    },
    sharing: function sharing(stime, etime, id) {
      var _this14 = this;
      Personshare_list({
        auth_rule_id: id,
        create_stime: stime,
        create_etime: etime,
        structure_ids: String(this.Studentleve_id)
      }).then(function (res) {
        var TurnoverRate = {
          prop: '成交率',
          label: '成交率'
        };
        res.data.tabletitle.unshift(TurnoverRate);
        var Efficiency = {
          prop: '有效率',
          label: '有效率'
        };
        res.data.tabletitle.unshift(Efficiency);
        var DataVolume = {
          prop: '数据量',
          label: '数据量'
        };
        res.data.data.map(function (res) {
          res.成交率 = res.成交率 + '%';
          res.有效率 = res.有效率 + '%';
        });
        res.data.tabletitle.unshift(DataVolume);
        _this14.sharingData = res.data.data;
        _this14.sharing_Tos = res.data.tabletitle;
      });
    },
    //流量共享统计数据所属部门
    handleChange: function handleChange(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      this.Studentleve_id = share == ',' ? '' : share;
    },
    // 用户渠道来源统计时间筛选
    channel_event: function channel_event(name) {
      var _this15 = this;
      if (this.kshasa.indexOf(name) != -1) {
        this.IDesarr.forEach(function (item) {
          if (item.title == name) {
            _this15.channel_stime = _this15.channel_time[0].toString().substring(0, 10);
            _this15.channel_etime = _this15.channel_time[1].toString().substring(0, 10);
            _this15.channel(_this15.channel_stime, _this15.channel_etime, item.id);
          }
        });
      } else {
        this.channel_stime = this.channel_time[0].toString().substring(0, 10);
        this.channel_etime = this.channel_time[1].toString().substring(0, 10);
        this.channel(this.channel_stime, this.channel_etime);
      }
    },
    channel: function channel(stime, etime, id) {
      var _this16 = this;
      personsource_list({
        auth_rule_id: id,
        create_stime: stime,
        create_etime: etime,
        source_ids: String(this.source_client_id),
        structure_ids: String(this.channelleve_id)
      }).then(function (res) {
        var TurnoverRate = {
          prop: '成交率',
          label: '成交率'
        };
        res.data.tabletitle.unshift(TurnoverRate);
        var Efficiency = {
          prop: '有效率',
          label: '有效率'
        };
        res.data.tabletitle.unshift(Efficiency);
        var DataVolume = {
          prop: '数据量',
          label: '数据量'
        };
        res.data.tabletitle.unshift(DataVolume);
        var name = {
          prop: 'flower_name',
          label: '渠道名称',
          width: '140',
          fixed: 'left'
        };
        res.data.data.map(function (res) {
          res.成交率 = res.成交率 + '%';
          res.有效率 = res.有效率 + '%';
        });
        res.data.tabletitle.unshift(name);
        _this16.channelData = res.data.data;
        _this16.channel_Tos = res.data.tabletitle;
      });
    },
    flowerArray: function flowerArray() {
      var _this17 = this;
      agentFlowerNameList().then(function (res) {
        _this17.flower_Array = res.data;
      });
    },
    //渠道来源统计数据所属部门
    channelChange: function channelChange(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      this.channelleve_id = share == ',' ? '' : share;
    },
    //用户流转数据详情
    circulation_event: function circulation_event(name) {
      var _this18 = this;
      this.circulationData = [];
      //个人流转数据详情
      var start_time = this.circulation_time[0].toString().substring(0, 10);
      var send_time = this.circulation_time[1].toString().substring(0, 10);
      if (this.kshasa.indexOf(name) != -1) {
        this.IDesarr.forEach(function (item) {
          if (item.title == name) {
            circulation_list({
              auth_rule_id: item.id,
              start_time: start_time,
              send_time: send_time
            }).then(function (res_liu) {
              _this18.circulationData = [res_liu.data];
            });
          }
        });
      } else {
        circulation_list({
          start_time: start_time,
          send_time: send_time
        }).then(function (res_liu) {
          _this18.circulationData = [res_liu.data];
        });
      }
    },
    //用户通话数据监控详情
    // conversation_event(name) {
    //     this.conversationdata = [];
    //     let start_time = this.conversation_time[0].toString().substring(0, 10);
    //     let send_time = this.conversation_time[1].toString().substring(0, 10);
    //     if (this.kshasa.indexOf(name) != -1) {
    //         this.IDesarr.forEach((item) => {
    //             if (item.title == name) {
    //                 Callperson_list({ auth_rule_id: item.id, stime: start_time, etime: send_time }).then((res_liu) => {
    //                     this.conversationdata = [res_liu.data];
    //                 });
    //             }
    //         });
    //     } else {
    //         Callperson_list({ stime: start_time, etime: send_time }).then((res_liu) => {
    //             this.conversationdata = [res_liu.data];
    //         });
    //     }
    // },
    //用户天润通话数据监控详情
    conversatian_event: function conversatian_event(name) {
      var _this19 = this;
      this.conversatiandata = [];
      var start_time = this.conversation_tian[0].toString().substring(0, 10);
      var send_time = this.conversation_tian[1].toString().substring(0, 10);
      CallTianRun({
        search_date_start: start_time,
        search_date_end: send_time
      }).then(function (res_liu) {
        _this19.conversatiandata = [res_liu.data];
      });
    },
    conversatian_event1: function conversatian_event1(name) {
      var _this20 = this;
      this.conversatiandata1 = [];
      var start_time1 = this.conversation_tian1[0].toString().substring(0, 10);
      var send_time1 = this.conversation_tian1[1].toString().substring(0, 10);
      CallIncoming({
        search_date_start: start_time1,
        search_date_end: send_time1
      }).then(function (res_liu) {
        _this20.conversatiandata1 = [res_liu.data];
      });
    },
    updateData: function updateData() {
      var _this21 = this;
      this.prohibit = true;
      SystemManualEdit({
        role_id: this.menuId,
        contents: this.editForm.content
      }).then(function (res) {
        _this21.prohibit = false;
        _this21.dialogFormVisible = false;
        _this21.$notify({
          title: '成功',
          message: '编辑成功',
          type: 'success'
        });
        SystemManualInfo({
          role_id: _this21.menuId
        }).then(function (res) {
          _this21.centerHtml = res.data.contents;
        });
      });
    }
  }
};