var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.course != 1
        ? _c(
            "el-container",
            { staticClass: "app-container CollegeManagement" },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["页面操作统计"],
                        expression: "['页面操作统计']",
                      },
                    ],
                  }),
                  _vm._v(" "),
                  _vm.display
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: "主管看板",
                            name: this.is_director == 1 ? "first" : "",
                          },
                        },
                        [
                          _c("el-card", { staticClass: "box-card" }, [
                            _c(
                              "div",
                              { staticClass: "data-icons" },
                              _vm._l(
                                _vm.executive_carData,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "item" },
                                    [
                                      _c("div", { staticClass: "item-icon" }, [
                                        _c("i", {
                                          class: item.icon,
                                          style: item.color,
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "item-info" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(item.title)),
                                        ]),
                                        _vm._v(" "),
                                        _c("h3", [_vm._v(_vm._s(item.count))]),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              staticStyle: { margin: "10px 0px" },
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: { margin: "10px 0px" },
                                  attrs: { gutter: 24 },
                                },
                                [_c("Head", { attrs: { name: _vm.titled1 } })],
                                1
                              ),
                              _vm._v(" "),
                              _vm.studentJudge
                                ? _c(
                                    "el-row",
                                    {
                                      staticStyle: {
                                        width: "500px",
                                        margin: "10px 0px",
                                      },
                                      attrs: { gutter: 12 },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { margin: "10px 0px" },
                                          attrs: { span: 11.5 },
                                        },
                                        [
                                          _c("span", [_vm._v("所属部门:")]),
                                          _vm._v(" "),
                                          _c("el-cascader", {
                                            attrs: {
                                              options: _vm.bumenoptions,
                                              clearable: "",
                                              filterable: "",
                                              size: "small",
                                              "expand-trigger": "hover",
                                              placeholder: "请选择部门",
                                              props: {
                                                value: "id",
                                                label: "name",
                                                multiple: true,
                                              },
                                              "collapse-tags": "",
                                            },
                                            model: {
                                              value: _vm.Studentleve_eid,
                                              callback: function ($$v) {
                                                _vm.Studentleve_eid = $$v
                                              },
                                              expression: "Studentleve_eid",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "warning",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.Studentleve_eChange(
                                                    "今日学员级别详情"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查询")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-bottom": "20px",
                                      },
                                      attrs: {
                                        data: _vm.Studentleve_elist,
                                        "row-key": "id",
                                        border: "",
                                        "tree-props": {
                                          children: "children",
                                          hasChildren: "hasChildren",
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.Studentleve_elistTo,
                                      function (info, index) {
                                        return _c("el-table-column", {
                                          key: index,
                                          attrs: {
                                            align: "center",
                                            prop: info.prop,
                                            width: info.width,
                                            label: info.label,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-card",
                            {
                              staticClass: "box-ka",
                              staticStyle: { margin: "10px 0px" },
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: { margin: "10px 0px" },
                                  attrs: { gutter: 24 },
                                },
                                [_c("Head", { attrs: { name: _vm.titled2 } })],
                                1
                              ),
                              _vm._v(" "),
                              _vm.theJudge
                                ? _c(
                                    "el-row",
                                    {
                                      staticStyle: {
                                        width: "500px",
                                        margin: "10px 0px",
                                      },
                                      attrs: { gutter: 12 },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { margin: "10px 0px" },
                                          attrs: { span: 11.5 },
                                        },
                                        [
                                          _c("span", [_vm._v("所属部门:")]),
                                          _vm._v(" "),
                                          _c("el-cascader", {
                                            attrs: {
                                              options: _vm.bumenoptions,
                                              clearable: "",
                                              filterable: "",
                                              size: "small",
                                              "expand-trigger": "hover",
                                              placeholder: "请选择部门",
                                              props: {
                                                value: "id",
                                                label: "name",
                                                multiple: true,
                                              },
                                              "collapse-tags": "",
                                            },
                                            model: {
                                              value: _vm.circulation_eid,
                                              callback: function ($$v) {
                                                _vm.circulation_eid = $$v
                                              },
                                              expression: "circulation_eid",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "warning",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.circulation_eChange(
                                                    "今日流转数据详情"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查询")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-bottom": "20px",
                                      },
                                      attrs: {
                                        data: _vm.circulation_elist,
                                        "row-key": "id",
                                        border: "",
                                        "tree-props": {
                                          children: "children",
                                          hasChildren: "hasChildren",
                                        },
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "name",
                                          label: "部门",
                                          width: "180px",
                                          fixed: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "person_count",
                                          label: "客户总量",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "create_count",
                                          label: "创建客户",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "linqu_count",
                                          label: "领取客户",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "share_count",
                                          label: "共享转入",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "fenpei_count",
                                          label: "分配转入",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "create_real_count",
                                          label: "创建人转入",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "follow_real_count",
                                          label: "跟进人转入",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "huigui_set_count",
                                          label: "回归公海客户",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "waisong_set_count",
                                          label: "外送公海客户",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "create_set_count",
                                          label: "创建人转出",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "follow_set_count",
                                          label: "跟进人转出",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "share_set_count",
                                          label: "共享转出",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: "用户看板",
                        name: this.is_director == 0 ? "first" : "second",
                      },
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          _vm._l(_vm.user_carData, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "box-kapian",
                                style: { background: _vm.colorData[item.id] },
                              },
                              _vm._l(item.list, function (val, int) {
                                return _c(
                                  "div",
                                  {
                                    key: int,
                                    class:
                                      item.id == 0
                                        ? "content"
                                        : item.id == 2
                                        ? "con"
                                        : item.id == 3
                                        ? "contherr"
                                        : "tent",
                                  },
                                  [
                                    _c("div", { staticClass: "card_num" }, [
                                      _vm._v(_vm._s(val.value)),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "card_name" }, [
                                      _vm._v(_vm._s(val.label)),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            )
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: { margin: "10px 0px" },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: { margin: "10px 0px" },
                              attrs: { gutter: 24 },
                            },
                            [_c("Head", { attrs: { name: _vm.user_titled1 } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticStyle: {
                                width: "500px",
                                margin: "10px 0px",
                              },
                              attrs: { gutter: 12 },
                            },
                            [
                              _vm.studentJudge1
                                ? _c(
                                    "el-col",
                                    {
                                      staticStyle: { margin: "10px 0px" },
                                      attrs: { span: 11.5 },
                                    },
                                    [
                                      _c("span", [_vm._v("进入私海日期：")]),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px",
                                          "margin-top": "10px",
                                        },
                                        attrs: {
                                          clearable: false,
                                          type: "daterange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          "value-format": "timestamp",
                                          size: "small",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                        },
                                        model: {
                                          value: _vm.Studentleve_time,
                                          callback: function ($$v) {
                                            _vm.Studentleve_time = $$v
                                          },
                                          expression: "Studentleve_time",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "warning",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.Studentleve_event(
                                                "学员级别详情"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.StudentleveData,
                                      border: "",
                                    },
                                  },
                                  _vm._l(
                                    _vm.StudentleveTos,
                                    function (info, index) {
                                      return _c("el-table-column", {
                                        key: index,
                                        attrs: {
                                          align: "center",
                                          prop: info.prop,
                                          label: info.label,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: { margin: "10px 0px" },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: { margin: "10px 0px" },
                              attrs: { gutter: 24 },
                            },
                            [_c("Head", { attrs: { name: _vm.user_titled2 } })],
                            1
                          ),
                          _vm._v(" "),
                          _vm.theJudge1
                            ? _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    width: "500px",
                                    margin: "10px 0px",
                                  },
                                  attrs: { gutter: 12 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { margin: "10px 0px" },
                                      attrs: { span: 11.5 },
                                    },
                                    [
                                      _c("span", [_vm._v("日期范围：")]),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px",
                                          "margin-top": "10px",
                                        },
                                        attrs: {
                                          clearable: false,
                                          type: "daterange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          "value-format": "timestamp",
                                          size: "small",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                        },
                                        model: {
                                          value: _vm.circulation_time,
                                          callback: function ($$v) {
                                            _vm.circulation_time = $$v
                                          },
                                          expression: "circulation_time",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "warning",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.circulation_event(
                                                "流转数据详情"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.circulationData,
                                      border: "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "person_count",
                                        label: "客户总量",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "create_count",
                                        label: "创建客户",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "linqu_count",
                                        label: "领取客户",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "share_count",
                                        label: "共享转入",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "fenpei_count",
                                        label: "分配转入",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "create_real_count",
                                        label: "创建人转入",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "follow_real_count",
                                        label: "跟进人转入",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "huigui_set_count",
                                        label: "回归公海客户",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "waisong_set_count",
                                        label: "外送公海客户",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "create_set_count",
                                        label: "创建人转出",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "follow_set_count",
                                        label: "跟进人转出",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "share_set_count",
                                        label: "共享转出",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: { margin: "10px 0px" },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: { margin: "10px 0px" },
                              attrs: { gutter: 24 },
                            },
                            [_c("Head", { attrs: { name: _vm.user_titled6 } })],
                            1
                          ),
                          _vm._v(" "),
                          _vm.conversationJudge1
                            ? _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    width: "500px",
                                    margin: "10px 0px",
                                  },
                                  attrs: { gutter: 12 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { margin: "10px 0px" },
                                      attrs: { span: 11.5 },
                                    },
                                    [
                                      _c("span", [_vm._v("日期范围：")]),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px",
                                          "margin-top": "10px",
                                        },
                                        attrs: {
                                          clearable: false,
                                          type: "daterange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          "value-format": "timestamp",
                                          size: "small",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                        },
                                        model: {
                                          value: _vm.conversation_tian,
                                          callback: function ($$v) {
                                            _vm.conversation_tian = $$v
                                          },
                                          expression: "conversation_tian",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "warning",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.conversatian_event(
                                                "昨日通话数据监控详情"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.conversatiandata,
                                      border: "",
                                      "header-cell-style": {
                                        textAlign: "center",
                                      },
                                      "cell-style": { textAlign: "center" },
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { prop: "name", label: "" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼出次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "exhale_total_1",
                                            label: "系统呼出次数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "exhale_total_2",
                                            label: "手机呼出次数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "exhale_total_all",
                                            label: "总呼出次数",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼出接通次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "connect_total_1",
                                            label: "系统呼出接通次数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "connect_total_2",
                                            label: "手机呼出接通次数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "connect_total_all",
                                            label: "总呼出接通次数",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼出接通率",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "rate_1",
                                            label: "系统呼出接通率",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "rate_2",
                                            label: "手机呼出接通率",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "rate_avg",
                                            label: "平均呼出接通率",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼出总通话时长",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_1",
                                            label: "系统呼出总通话时长",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_2",
                                            label: "手机呼出总通话时长",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_all",
                                            label: "呼出总通话时长",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼出平均通话时长",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_avg_1",
                                            label: "系统呼出平均时长",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_avg_2",
                                            label: "手机呼出总通话时长",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_avg_all",
                                            label: "总呼出平均通话时长",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "0-30s通话次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_1_1",
                                            label: "系统",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_1_2",
                                            label: "手机",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_1_total",
                                            label: "总计",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "30-60s通话次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_2_1",
                                            label: "系统",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_2_2",
                                            label: "手机",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_2_total",
                                            label: "总计",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "60-100s通话次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_3_1",
                                            label: "系统",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_3_2",
                                            label: "手机",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_3_total",
                                            label: "总计",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "100-200s通话次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_4_1",
                                            label: "系统",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_4_2",
                                            label: "手机",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_4_total",
                                            label: "总计",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "200-300s通话次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_5_1",
                                            label: "系统",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_5_2",
                                            label: "手机",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_5_total",
                                            label: "总计",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "300s以上通话次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_6_1",
                                            label: "系统",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_6_2",
                                            label: "手机",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "number_6_total",
                                            label: "总计",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: { margin: "10px 0px" },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: { margin: "10px 0px" },
                              attrs: { gutter: 24 },
                            },
                            [_c("Head", { attrs: { name: _vm.user_titled7 } })],
                            1
                          ),
                          _vm._v(" "),
                          _vm.conversationJudge2
                            ? _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    width: "500px",
                                    margin: "10px 0px",
                                  },
                                  attrs: { gutter: 12 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { margin: "10px 0px" },
                                      attrs: { span: 11.5 },
                                    },
                                    [
                                      _c("span", [_vm._v("日期范围：")]),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px",
                                          "margin-top": "10px",
                                        },
                                        attrs: {
                                          clearable: false,
                                          type: "daterange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          "value-format": "timestamp",
                                          size: "small",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                        },
                                        model: {
                                          value: _vm.conversation_tian1,
                                          callback: function ($$v) {
                                            _vm.conversation_tian1 = $$v
                                          },
                                          expression: "conversation_tian1",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "warning",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.conversatian_event1(
                                                "呼入通话监控详情"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.conversatiandata1,
                                      border: "",
                                      "header-cell-style": {
                                        textAlign: "center",
                                      },
                                      "cell-style": { textAlign: "center" },
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { prop: "name", label: "" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼入次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "come_in_total_1",
                                            label: "系统呼入次数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "come_in_total_2",
                                            label: "手机呼入次数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "come_in_total_all",
                                            label: "总呼入次数",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼入接通次数",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "connect_total_1",
                                            label: "系统呼入接通次数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "connect_total_2",
                                            label: "手机呼入接通次数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "connect_total_all",
                                            label: "总呼入接通次数",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼入接通率",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "rate_1",
                                            label: "系统呼入接通率",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "rate_2",
                                            label: "手机呼入接通率",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "rate_avg",
                                            label: "平均呼入接通率",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼入总通话时长",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_1",
                                            label: "系统呼入总通话时长",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_2",
                                            label: "手机呼入总通话时长",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_all",
                                            label: "呼入总通话时长",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "num",
                                          label: "呼入平均通话时长",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_avg_1",
                                            label: "系统呼入平均时长",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_avg_2",
                                            label: "手机呼入总通话时长",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "duration_avg_all",
                                            label: "总呼入平均通话时长",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: { margin: "10px 0px" },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: { margin: "10px 0px" },
                              attrs: { gutter: 24 },
                            },
                            [_c("Head", { attrs: { name: _vm.user_titled4 } })],
                            1
                          ),
                          _vm._v(" "),
                          _vm.flowJudge1
                            ? _c(
                                "el-row",
                                {
                                  staticStyle: { margin: "10px 0px" },
                                  attrs: { gutter: 12 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { margin: "10px 0px" },
                                      attrs: { span: 11.5 },
                                    },
                                    [
                                      _c("span", [_vm._v("创建日期：")]),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px",
                                          "margin-top": "10px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          clearable: false,
                                          type: "daterange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          "value-format": "timestamp",
                                          size: "small",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                        },
                                        model: {
                                          value: _vm.sharing_time,
                                          callback: function ($$v) {
                                            _vm.sharing_time = $$v
                                          },
                                          expression: "sharing_time",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("数据所属部门：")]),
                                      _vm._v(" "),
                                      _c("el-cascader", {
                                        attrs: {
                                          options: _vm.bumenoptions,
                                          clearable: "",
                                          filterable: "",
                                          size: "small",
                                          "expand-trigger": "hover",
                                          placeholder: "请选择部门",
                                          "collapse-tags": "",
                                          props: {
                                            value: "id",
                                            label: "name",
                                            multiple: true,
                                          },
                                        },
                                        on: { change: _vm.handleChange },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "warning",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sharing_event(
                                                "流量共享统计"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              [
                                _c(
                                  "el-table",
                                  {
                                    ref: "multipleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.sharingData,
                                      "row-key": "id",
                                      border: "",
                                      "tree-props": {
                                        children: "children",
                                        hasChildren: "hasChildren",
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.sharing_Tos,
                                    function (info, index) {
                                      return _c("el-table-column", {
                                        key: index,
                                        attrs: {
                                          align: "center",
                                          prop: info.prop,
                                          width: info.width,
                                          label: info.label,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: { margin: "10px 0px" },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: { margin: "10px 0px" },
                              attrs: { gutter: 24 },
                            },
                            [_c("Head", { attrs: { name: _vm.user_titled5 } })],
                            1
                          ),
                          _vm._v(" "),
                          _vm.channelJudge1
                            ? _c(
                                "el-row",
                                {
                                  staticStyle: { margin: "10px 0px" },
                                  attrs: { gutter: 12 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { margin: "10px 0px" },
                                      attrs: { span: 11.5 },
                                    },
                                    [
                                      _c("span", [_vm._v("创建日期：")]),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px",
                                          "margin-top": "10px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          clearable: false,
                                          type: "daterange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          "value-format": "timestamp",
                                          size: "small",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                        },
                                        model: {
                                          value: _vm.channel_time,
                                          callback: function ($$v) {
                                            _vm.channel_time = $$v
                                          },
                                          expression: "channel_time",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("渠道来源：")]),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            size: "small",
                                            filterable: "",
                                            multiple: "",
                                            "collapse-tags": "",
                                            placeholder: "请搜索来源",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.source_client_id,
                                            callback: function ($$v) {
                                              _vm.source_client_id = $$v
                                            },
                                            expression: "source_client_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.flower_Array,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.flower_name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("数据所属部门：")]),
                                      _vm._v(" "),
                                      _c("el-cascader", {
                                        attrs: {
                                          options: _vm.bumenoptions,
                                          clearable: "",
                                          filterable: "",
                                          size: "small",
                                          "expand-trigger": "hover",
                                          placeholder: "请选择部门",
                                          "collapse-tags": "",
                                          props: {
                                            value: "id",
                                            label: "name",
                                            multiple: true,
                                          },
                                        },
                                        on: { change: _vm.channelChange },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "warning",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.channel_event(
                                                "渠道来源统计"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              [
                                _c(
                                  "el-table",
                                  {
                                    ref: "multipleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.channelData,
                                      "row-key": "id",
                                      border: "",
                                      "tree-props": {
                                        children: "children",
                                        hasChildren: "hasChildren",
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.channel_Tos,
                                    function (info, index) {
                                      return _c("el-table-column", {
                                        key: index,
                                        attrs: {
                                          align: "center",
                                          prop: info.prop,
                                          fixed: info.fixed,
                                          width: info.width,
                                          label: info.label,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("Heads", { attrs: { name: _vm.title } }),
                      _vm._v(" "),
                      _c("div", {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: [""],
                            expression: "['']",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tabs",
                {
                  ref: "tabs",
                  on: { "tab-click": _vm.courseClick },
                  model: {
                    value: _vm.courseName,
                    callback: function ($$v) {
                      _vm.courseName = $$v
                    },
                    expression: "courseName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "页面功能介绍", name: "introduction" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _vm.tutorialEs
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { float: "right" },
                                      attrs: { size: "small", type: "primary" },
                                      on: { click: _vm.detailsClick },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.centerHtml) },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "页面操作统计", name: "statistics" } },
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { type: "border-card" },
                          on: { "tab-click": _vm.StatisticsClick },
                          model: {
                            value: _vm.coursekanban,
                            callback: function ($$v) {
                              _vm.coursekanban = $$v
                            },
                            expression: "coursekanban",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "主管看板", name: "director" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { padding: "0 20px" } },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 24 } },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.operatorShow,
                                                  expression: "operatorShow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("操作人：")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "250px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请搜索操作人",
                                                    size: "small",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.Buried
                                                        .search_realname,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.Buried,
                                                        "search_realname",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "Buried.search_realname",
                                                  },
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      type: "primary",
                                                      size: "small",
                                                      icon: "el-icon-search",
                                                    },
                                                    on: {
                                                      click: _vm.operatorClick,
                                                    },
                                                    slot: "append",
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "操作人"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.userNameshow,
                                                  expression: "userNameshow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("用户名：")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "250px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请搜索用户名",
                                                    size: "small",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.Buried
                                                        .search_username,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.Buried,
                                                        "search_username",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "Buried.search_username",
                                                  },
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      type: "primary",
                                                      size: "small",
                                                      icon: "el-icon-search",
                                                    },
                                                    on: {
                                                      click: _vm.userNamesClick,
                                                    },
                                                    slot: "append",
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "用户名"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.typeShow,
                                                  expression: "typeShow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("操作类型：")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "250px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请搜索类型",
                                                    size: "small",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.Buried
                                                        .search_auth_rule_title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.Buried,
                                                        "search_auth_rule_title",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "Buried.search_auth_rule_title",
                                                  },
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      type: "primary",
                                                      size: "small",
                                                      icon: "el-icon-search",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.noteAppendedClick,
                                                    },
                                                    slot: "append",
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "操作类型"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.operationDataShow,
                                                  expression:
                                                    "operationDataShow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("操作日期：")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-date-picker", {
                                                staticStyle: {
                                                  width: "250px !important",
                                                },
                                                attrs: {
                                                  size: "small",
                                                  type: "datetimerange",
                                                  "start-placeholder":
                                                    "开始日期",
                                                  "end-placeholder":
                                                    "  结束日期",
                                                  "default-time": [
                                                    "00:00:00",
                                                    "23:59:59",
                                                  ],
                                                  "value-format": "timestamp",
                                                },
                                                on: {
                                                  change: _vm.FinalFollowUpEs,
                                                },
                                                model: {
                                                  value: _vm.finalFollow,
                                                  callback: function ($$v) {
                                                    _vm.finalFollow = $$v
                                                  },
                                                  expression: "finalFollow",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "操作日期"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.BelongingShow,
                                                  expression: "BelongingShow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("操作人所属部门：")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-cascader", {
                                                attrs: {
                                                  placeholder: "请选择部门",
                                                  options: _vm.bumenoptions,
                                                  props: {
                                                    value: "id",
                                                    label: "name",
                                                    multiple: true,
                                                  },
                                                  size: "small",
                                                  "collapse-tags": "",
                                                  clearable: "",
                                                },
                                                on: {
                                                  change: _vm.structureclick,
                                                },
                                                model: {
                                                  value: _vm.structure_id,
                                                  callback: function ($$v) {
                                                    _vm.structure_id = $$v
                                                  },
                                                  expression: "structure_id",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "操作人所属部门"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-main",
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.BuriedData1,
                                            border: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "realname",
                                                align: "center",
                                                label: "操作人",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "操作人"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("操作人")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "username",
                                                align: "center",
                                                label: "用户名",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "用户名"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("用户名")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "auth_rule_title",
                                                align: "center",
                                                label: "操作类型",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "操作类型"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("操作类型")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "create_time",
                                                align: "center",
                                                label: "操作日期",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "操作日期"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("操作日期")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "structure_name",
                                                align: "center",
                                                label: "操作人所属部门",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "操作人所属部门"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("操作人所属部门")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-pagination", {
                                    staticClass: "pagination",
                                    attrs: {
                                      "current-page": _vm.page,
                                      "page-sizes": [15, 20, 30, 50, 100],
                                      "page-size": _vm.pagesize,
                                      layout:
                                        "total, sizes, prev, pager, next, jumper",
                                      total: _vm.total,
                                    },
                                    on: {
                                      "size-change": _vm.handleSizeChange,
                                      "current-change": _vm.handleCurrentChange,
                                      "update:currentPage": function ($event) {
                                        _vm.page = $event
                                      },
                                      "update:current-page": function ($event) {
                                        _vm.page = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: { label: "用户看板", name: "userkanban" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { padding: "0 20px" } },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 24 } },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.operatorShow,
                                                  expression: "operatorShow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("操作人：")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "250px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请搜索操作人",
                                                    size: "small",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.Buried
                                                        .search_realname,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.Buried,
                                                        "search_realname",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "Buried.search_realname",
                                                  },
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      type: "primary",
                                                      size: "small",
                                                      icon: "el-icon-search",
                                                    },
                                                    on: {
                                                      click: _vm.operatorClick,
                                                    },
                                                    slot: "append",
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "操作人"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.userNameshow,
                                                  expression: "userNameshow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("用户名：")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "250px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请搜索用户名",
                                                    size: "small",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.Buried
                                                        .search_username,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.Buried,
                                                        "search_username",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "Buried.search_username",
                                                  },
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      type: "primary",
                                                      size: "small",
                                                      icon: "el-icon-search",
                                                    },
                                                    on: {
                                                      click: _vm.userNamesClick,
                                                    },
                                                    slot: "append",
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "用户名"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.typeShow,
                                                  expression: "typeShow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("操作类型：")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "250px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请搜索类型",
                                                    size: "small",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.Buried
                                                        .search_auth_rule_title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.Buried,
                                                        "search_auth_rule_title",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "Buried.search_auth_rule_title",
                                                  },
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      type: "primary",
                                                      size: "small",
                                                      icon: "el-icon-search",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.noteAppendedClick,
                                                    },
                                                    slot: "append",
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "操作类型"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.operationDataShow,
                                                  expression:
                                                    "operationDataShow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("操作日期：")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-date-picker", {
                                                staticStyle: {
                                                  width: "250px !important",
                                                },
                                                attrs: {
                                                  size: "small",
                                                  type: "datetimerange",
                                                  "start-placeholder":
                                                    "开始日期",
                                                  "end-placeholder":
                                                    "  结束日期",
                                                  "default-time": [
                                                    "00:00:00",
                                                    "23:59:59",
                                                  ],
                                                  "value-format": "timestamp",
                                                },
                                                on: {
                                                  change: _vm.FinalFollowUpEs,
                                                },
                                                model: {
                                                  value: _vm.finalFollow,
                                                  callback: function ($$v) {
                                                    _vm.finalFollow = $$v
                                                  },
                                                  expression: "finalFollow",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "操作日期"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 16,
                                            md: 13,
                                            lg: 11,
                                            xl: 11,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.BelongingShow,
                                                  expression: "BelongingShow",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "15px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "nameTitle projectScreening",
                                                },
                                                [_vm._v("操作人所属部门：")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-cascader", {
                                                attrs: {
                                                  placeholder: "请选择部门",
                                                  options: _vm.bumenoptions,
                                                  props: {
                                                    value: "id",
                                                    label: "name",
                                                    multiple: true,
                                                  },
                                                  size: "small",
                                                  "collapse-tags": "",
                                                  clearable: "",
                                                },
                                                on: {
                                                  change: _vm.structureclick,
                                                },
                                                model: {
                                                  value: _vm.structure_id,
                                                  callback: function ($$v) {
                                                    _vm.structure_id = $$v
                                                  },
                                                  expression: "structure_id",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Deletesshow(
                                                        "操作人所属部门"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-main",
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.BuriedData1,
                                            border: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "realname",
                                                align: "center",
                                                label: "操作人",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "操作人"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("操作人")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "username",
                                                align: "center",
                                                label: "用户名",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "用户名"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("用户名")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "auth_rule_title",
                                                align: "center",
                                                label: "操作类型",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "操作类型"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("操作类型")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "create_time",
                                                align: "center",
                                                label: "操作日期",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "操作日期"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("操作日期")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                prop: "structure_name",
                                                align: "center",
                                                label: "操作人所属部门",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                        color: "#1890FF",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Activeshow(
                                                            "操作人所属部门"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("操作人所属部门")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-pagination", {
                                    staticClass: "pagination",
                                    attrs: {
                                      "current-page": _vm.page,
                                      "page-sizes": [10, 20, 30, 50, 100],
                                      "page-size": _vm.pagesize,
                                      layout:
                                        "total, sizes, prev, pager, next, jumper",
                                      total: _vm.total,
                                    },
                                    on: {
                                      "size-change": _vm.handleSizeChange,
                                      "current-change": _vm.handleCurrentChange,
                                      "update:currentPage": function ($event) {
                                        _vm.page = $event
                                      },
                                      "update:current-page": function ($event) {
                                        _vm.page = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "60%",
                    title: "编辑",
                    visible: _vm.dialogFormVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogFormVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    { ref: "editForm", attrs: { "label-width": "100px" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "简介" },
                        },
                        [
                          _c("quill-editor", {
                            ref: "myTextEditor",
                            staticClass: "ql-container",
                            staticStyle: { height: "400px" },
                            attrs: { options: _vm.editorOption },
                            model: {
                              value: _vm.editForm.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "content", $$v)
                              },
                              expression: "editForm.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "padding-top": "50px" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", disabled: _vm.prohibit },
                          on: { click: _vm.updateData },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }